import Head from "next/head";
import React from "react";

const MetaHead = ({ ...props }) => {

	const prop = props;
	const data = {
		title: prop.title || "The Biggest Beauty Event In The World - Yogyakarta X Beauty",
		description: prop.description || "Yogyakarta X Beauty want to unite the whole beauty community in 4 days of fun and insightful more than shopping experience.",
		// keywords: prop.keywords || "Education,Mommies Working It,Parenting,Relationship",
		url: prop.url || "https://yogyakarta.xbeauty.id/",
		canonical: prop.canonical || "https://yogyakarta.xbeauty.id/",
	};

	return (
		<Head>
			<title>
				{data.title}
			</title>

			{/* META SEO */}
			<link rel="canonical" href={data.canonical} />
			<meta name="description" content={data.description} />
			{/* <meta name="keywords" content={data.keywords} /> */}

			<meta property="og:description" content={data.description} />
			<meta property="og:url" content={data.url} />
		</Head>
	);

};

export default MetaHead;
